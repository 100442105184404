// components/SteinDetail.js
import React, { useState, useEffect, useCallback } from 'react';
import { useParams, Navigate, useNavigate, useLocation  } from 'react-router-dom';
import API from '../services/api';
import '../styles/SteinDetail.css';
import '../styles/Form.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faTimes, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';

function SteinDetail() {
  const { id } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const editMode = queryParams.get('edit') === 'true';
  const [stein, setStein] = useState(null);
  const [isEditing, setIsEditing] = useState(editMode);
  const userRole = localStorage.getItem('role') || 'guest';
  const navigate = useNavigate();
  const [showNewSerienBezeichnung, setShowNewSerienBezeichnung] = useState(false);

  // Definieren von fetchStein mit useCallback
  const fetchStein = useCallback(() => {
    API.get(`/steine/${id}`)
      .then((res) => {
        setStein(res.data); // Zustand setzen
      })
      .catch((err) => console.error(err));
  }, [id]);

  useEffect(() => {
    if (userRole === 'guest') {
      // Gastbenutzer haben keinen Zugriff auf die Detailseite
      alert('Sie haben keine Berechtigung, diese Seite aufzurufen.');
      navigate('/');
    } else {
      if (id === 'new') {
        if (userRole !== 'admin') {
          alert('Sie haben keine Berechtigung, einen neuen Stein zu erstellen.');
          return;
        }
        setStein({
          name: '',
          farbe: '',
          durchmesser: null,
          laenge: null,
          breite: null,
          gewicht: null,
          preisProGramm: null,
          istSerie: false,
          serienBezeichnung: '',
          kategorie: '',
          status: 'potenzieller Kauf',
          kaufPreis: null,
          kaufDatum: '',
          kaufOrt: '',
          verkaeufer: '',
          verkaufPreis: null,
          verkaufDatum: '',
          verkaufOrt: '',
          kaeufer: '',
          notizen: '',
          bildPfad: '',
          bildDatei: null,
        });
        setIsEditing(true);
      } else {
        fetchStein();
      }
    }
  }, [id, userRole, navigate, fetchStein]);

  const [serienBezeichnungOptions, setSerienBezeichnungOptions] = useState([]);

  useEffect(() => {
    API.get('/steine/serienBezeichnungen')
      .then((res) => setSerienBezeichnungOptions(res.data.filter((serien) => serien && serien.trim() !== '')))
      .catch((err) => console.error(err));
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
  
    let newValue;
    if (type === 'checkbox') {
      newValue = checked;
    } else if (type === 'number' || type === 'date') {
      newValue = value === '' ? null : value; // Konvertiere leere Werte zu `null`
    } else {
      newValue = value === '' || value === 'null' ? null : value; // Konvertiere 'null' String zu `null`
    }
  
    setStein((prevState) => ({
      ...prevState,
      [name]: newValue,
    }));
  };
  
  
  
  
  const handleSave = (e) => {
    e.preventDefault();
  
    if (!stein.name) {
      alert('Der Name ist ein Pflichtfeld.');
      return;
    }
  
    const formData = new FormData();
    const cleanedStein = { ...stein };
  
    // Bereinige die Daten
    Object.keys(cleanedStein).forEach((key) => {
      if (cleanedStein[key] === '' || cleanedStein[key] === 'null') {
        cleanedStein[key] = null;
      } else if (['durchmesser', 'laenge', 'breite', 'gewicht', 'preisProGramm', 'kaufPreis', 'verkaufPreis'].includes(key)) {
        cleanedStein[key] = cleanedStein[key] ? parseFloat(cleanedStein[key]) : null;
      } else if (['kaufDatum', 'verkaufDatum'].includes(key)) {
        cleanedStein[key] = cleanedStein[key] ? new Date(cleanedStein[key]).toISOString() : null;
      } else if (key === 'istSerie') {
        cleanedStein[key] = Boolean(cleanedStein[key]);
      }
    });
  
    for (const key in cleanedStein) {
      if (key !== 'bildPfad' && key !== 'bildDatei') {
        formData.append(key, cleanedStein[key]);
      }
    }
  
    if (stein.bildDatei) {
      formData.append('bild', stein.bildDatei);
    }
  
    if (id === 'new') {
      API.post('/steine', formData)
        .then((res) => {
          setStein(res.data);
          setIsEditing(false);
          navigate(`/steine/${res.data._id}`);
        })
        .catch((err) => {
          console.error('Fehler beim Erstellen des Steins:', err.response?.data || err.message);
          alert(err.response?.data || 'Fehler beim Erstellen des Steins.');
        });
    } else {
      API.put(`/steine/${id}`, formData)
        .then((res) => {
          setStein(res.data);
          setIsEditing(false);
        })
        .catch((err) => {
          console.error('Fehler beim Aktualisieren des Steins:', err.response?.data || err.message);
          alert(err.response?.data || 'Fehler beim Aktualisieren des Steins.');
        });
    }
  };
  
  
  
  
  
  

  const handleDelete = () => {
    if (window.confirm('Sind Sie sicher, dass Sie diesen Stein löschen möchten?')) {
      API.delete(`/steine/${id}`)
        .then(() => {
          navigate('/');
        })
        .catch((err) => {
          console.error(err);
          alert('Fehler beim Löschen des Steins.');
        });
    }
  };

  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setStein((prevState) => ({
        ...prevState,
        bildDatei: e.target.files[0],
      }));
    }
  };

  const handleEdit = () => {
    if (userRole === 'admin') {
      setIsEditing(true);
    } else {
      alert('Sie haben keine Berechtigung zum Bearbeiten.');
    }
  };

  // 2) handleBack-Button, der zur Liste zurückgeht
  const handleBack = () => {
    // Falls wir die scrollPosition aus location.state beibehalten wollen
    navigate('/', { state: { scrollPosition: location.state?.scrollPosition || 0 } });

  };

  if (id === 'new' && userRole !== 'admin') {
    return <Navigate to="/" />;
  }

  if (!stein) {
    return <div>Laden...</div>;
  }

  const canEdit = userRole === 'admin';

  return (
    <main>
      <div className="stein-detail">
        {isEditing ? (
          <>
            <h2>{id === 'new' ? 'Neuen Stein erstellen' : 'Stein bearbeiten'}</h2>
            <form className="stein-form" onSubmit={handleSave}>
              <div className="form-group">
                <label htmlFor="name">
                  Name<span className="required">*</span>:
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={stein.name}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="farbe">Farbe:</label>
                <input
                  type="text"
                  id="farbe"
                  name="farbe"
                  value={stein.farbe || ''}
                  onChange={handleChange}
                />
              </div>

              <div className="form-group">
                <label htmlFor="durchmesser">Durchmesser (mm):</label>
                <input
                  type="number"
                  id="durchmesser"
                  name="durchmesser"
                  value={stein.durchmesser || ''}
                  onChange={handleChange}
                />
              </div>

              <div className="form-group">
                <label htmlFor="laenge">Länge (mm):</label>
                <input
                  type="number"
                  id="laenge"
                  name="laenge"
                  value={stein.laenge || ''}
                  onChange={handleChange}
                />
              </div>

              <div className="form-group">
                <label htmlFor="breite">Breite (mm):</label>
                <input
                  type="number"
                  id="breite"
                  name="breite"
                  value={stein.breite || ''}
                  onChange={handleChange}
                />
              </div>

              <div className="form-group">
                <label htmlFor="gewicht">Gewicht (g):</label>
                <input
                  type="number"
                  id="gewicht"
                  name="gewicht"
                  value={stein.gewicht || ''}
                  onChange={handleChange}
                />
              </div>

              <div className="form-group">
                <label htmlFor="preisProGramm">Preis pro Gramm (€):</label>
                <input
                  type="number"
                  id="preisProGramm"
                  name="preisProGramm"
                  value={stein.preisProGramm || ''}
                  onChange={handleChange}
                  step="0.01"
                />
              </div>

              <div className="form-group checkbox-group">
                <label htmlFor="istSerie">
                  <input
                    type="checkbox"
                    id="istSerie"
                    name="istSerie"
                    checked={stein.istSerie}
                    onChange={handleChange}
                  />
                  Teil einer Serie
                </label>
              </div>

              {stein.istSerie && (
                <div className="form-group">
                  <label htmlFor="serienBezeichnung">Serienbezeichnung:</label>
                  <select
                    id="serienBezeichnung"
                    name="serienBezeichnung"
                    value={stein.serienBezeichnung || ''}
                    onChange={(e) => {
                      if (e.target.value === 'neu') {
                        setShowNewSerienBezeichnung(true);
                        setStein({ ...stein, serienBezeichnung: '' });
                      } else {
                        setShowNewSerienBezeichnung(false);
                        handleChange(e);
                      }
                    }}
                  >
                    {/* Die Option "Neue Serienbezeichnung hinzufügen" immer ganz oben */}
                    <option value="neu" className="new-serien-option">Neue Serienbezeichnung hinzufügen</option>
                    <option value="">Bitte wählen</option>
                    {serienBezeichnungOptions.map((serien) => (
                      <option key={serien} value={serien}>
                        {serien}
                      </option>
                    ))}
                  </select>
                </div>
              )}

              {/* Eingabefeld für neue Serienbezeichnung, nur anzeigen, wenn die Option "Neue Serienbezeichnung hinzufügen" ausgewählt ist */}
              {showNewSerienBezeichnung && (
                <div className="form-group">
                  <label htmlFor="neueSerienBezeichnung">Neue Serienbezeichnung:</label>
                  <input
                    type="text"
                    id="neueSerienBezeichnung"
                    name="serienBezeichnung"
                    value={stein.serienBezeichnung || ''}
                    onChange={handleChange}
                  />
                </div>
              )}


              <div className="form-group">
                <label htmlFor="kategorie">Kategorie:</label>
                <input
                  type="text"
                  id="kategorie"
                  name="kategorie"
                  value={stein.kategorie || ''}
                  onChange={handleChange}
                />
              </div>

              <div className="form-group">
                <label htmlFor="status">Status:</label>
                <select
                  id="status"
                  name="status"
                  value={stein.status || ''}
                  onChange={handleChange}
                >
                  <option value="potenzieller Kauf">Potenzieller Kauf</option>
                  <option value="gekauft">Gekauft</option>
                  <option value="verkauft">Verkauft</option>
                  <option value="noch keinen Kontakt">Noch keinen Kontakt</option>
                </select>
              </div>

              {/* Kaufdetails */}
              <fieldset className="details-fieldset">
                <legend>Kaufdetails</legend>
                <div className="form-group">
                  <label htmlFor="kaufPreis">Kaufpreis (€):</label>
                  <input
                    type="number"
                    id="kaufPreis"
                    name="kaufPreis"
                    value={stein.kaufPreis || ''}
                    onChange={handleChange}
                    step="0.01"
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="kaufDatum">Kaufdatum:</label>
                  <input
                    type="date"
                    id="kaufDatum"
                    name="kaufDatum"
                    value={stein.kaufDatum ? stein.kaufDatum.substr(0, 10) : ''}
                    onChange={handleChange}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="kaufOrt">Kaufort:</label>
                  <input
                    type="text"
                    id="kaufOrt"
                    name="kaufOrt"
                    value={stein.kaufOrt || ''}
                    onChange={handleChange}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="verkaeufer">Verkäufer:</label>
                  <input
                    type="text"
                    id="verkaeufer"
                    name="verkaeufer"
                    value={stein.verkaeufer || ''}
                    onChange={handleChange}
                  />
                </div>
              </fieldset>

              {/* Verkaufsdetails */}
              <fieldset className="details-fieldset">
                <legend>Verkaufsdetails</legend>
                <div className="form-group">
                  <label htmlFor="verkaufPreis">Verkaufspreis (€):</label>
                  <input
                    type="number"
                    id="verkaufPreis"
                    name="verkaufPreis"
                    value={stein.verkaufPreis || ''}
                    onChange={handleChange}
                    step="0.01"
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="verkaufDatum">Verkaufsdatum:</label>
                  <input
                    type="date"
                    id="verkaufDatum"
                    name="verkaufDatum"
                    value={stein.verkaufDatum ? stein.verkaufDatum.substr(0, 10) : ''}
                    onChange={handleChange}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="verkaufOrt">Verkaufsort:</label>
                  <input
                    type="text"
                    id="verkaufOrt"
                    name="verkaufOrt"
                    value={stein.verkaufOrt || ''}
                    onChange={handleChange}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="kaeufer">Käufer:</label>
                  <input
                    type="text"
                    id="kaeufer"
                    name="kaeufer"
                    value={stein.kaeufer || ''}
                    onChange={handleChange}
                  />
                </div>
              </fieldset>

              <div className="form-group">
                <label htmlFor="notizen">Notizen:</label>
                <textarea
                  id="notizen"
                  name="notizen"
                  value={stein.notizen || ''}
                  onChange={handleChange}
                ></textarea>
              </div>

              <div className="form-group">
                <label htmlFor="bild">Bild hochladen:</label>
                <input
                  type="file"
                  id="bild"
                  name="bild"
                  accept="image/*"
                  capture="environment"
                  onChange={handleImageChange}
                />
              </div>

              <div className="button-group">
                <button type="submit" className="btn btn-submit">
                  <FontAwesomeIcon icon={faSave} /> Speichern
                </button>
                <button
                  type="button"
                  className="btn btn-cancel"
                  onClick={() => setIsEditing(false)}
                >
                  <FontAwesomeIcon icon={faTimes} /> Abbrechen
                </button>
              </div>
            </form>
          </>
        ) : (
          <>
            <h2>{stein.name}</h2>
            {stein && stein.bildPfad && (
              <img
                src={`${process.env.REACT_APP_BASE_URL}/${stein.bildPfad}?t=${new Date().getTime()}`}
                alt={stein.name}
                className="detail-image"
                onError={(e) =>
                  console.error(
                    'Fehler beim Laden des Bildes:',
                    `${process.env.REACT_APP_API_URL}/${stein.bildPfad}`
                  )
                }
              />
            )}
            <div className="stein-info">
              {/* Allgemeine Informationen */}
              <div className="info-section">
                {stein.farbe && (
                  <p>
                    <strong>Farbe:</strong> {stein.farbe}
                  </p>
                )}
                {stein.durchmesser && (
                  <p>
                    <strong>Durchmesser (mm):</strong> {stein.durchmesser}
                  </p>
                )}
                {stein.gewicht && (
                  <p>
                    <strong>Gewicht (g):</strong> {stein.gewicht}
                  </p>
                )}
                {stein.preisProGramm && (
                  <p>
                    <strong>Preis pro Gramm (€):</strong> {stein.preisProGramm}
                  </p>
                )}
                <p>
                  <strong>Ist Teil einer Serie:</strong> {stein.istSerie ? 'Ja' : 'Nein'}
                </p>
                {stein.istSerie && stein.serienBezeichnung && (
                  <p>
                    <strong>Serienbezeichnung:</strong> {stein.serienBezeichnung}
                  </p>
                )}
                {stein.kategorie && (
                  <p>
                    <strong>Kategorie:</strong> {stein.kategorie}
                  </p>
                )}
                {stein.status && (
                  <p>
                    <strong>Status:</strong> {stein.status}
                  </p>
                )}
                {stein.laenge && (
                  <p>
                    <strong>Länge (mm):</strong> {stein.laenge}
                  </p>
                )}
                {stein.breite && (
                  <p>
                    <strong>Breite (mm):</strong> {stein.breite}
                  </p>
                )}
              </div>

              {/* Kaufdetails */}
              {(stein.kaufPreis ||
                stein.kaufDatum ||
                stein.kaufOrt ||
                stein.verkaeufer) && (
                <div className="info-section">
                  <h3>Kaufdetails</h3>
                  {stein.kaufPreis && (
                    <p>
                      <strong>Kaufpreis (€):</strong> {stein.kaufPreis}
                    </p>
                  )}
                  {stein.kaufDatum && (
                    <p>
                      <strong>Kaufdatum:</strong>{' '}
                      {new Date(stein.kaufDatum).toLocaleDateString()}
                    </p>
                  )}
                  {stein.kaufOrt && (
                    <p>
                      <strong>Kaufort:</strong> {stein.kaufOrt}
                    </p>
                  )}
                  {stein.verkaeufer && (
                    <p>
                      <strong>Verkäufer:</strong> {stein.verkaeufer}
                    </p>
                  )}
                </div>
              )}

              {/* Verkaufsdetails */}
              {(stein.verkaufPreis ||
                stein.verkaufDatum ||
                stein.verkaufOrt ||
                stein.kaeufer) && (
                <div className="info-section">
                  <h3>Verkaufsdetails</h3>
                  {stein.verkaufPreis && (
                    <p>
                      <strong>Verkaufspreis (€):</strong> {stein.verkaufPreis}
                    </p>
                  )}
                  {stein.verkaufDatum && (
                    <p>
                      <strong>Verkaufsdatum:</strong>{' '}
                      {new Date(stein.verkaufDatum).toLocaleDateString()}
                    </p>
                  )}
                  {stein.verkaufOrt && (
                    <p>
                      <strong>Verkaufsort:</strong> {stein.verkaufOrt}
                    </p>
                  )}
                  {stein.kaeufer && (
                    <p>
                      <strong>Käufer:</strong> {stein.kaeufer}
                    </p>
                  )}
                </div>
              )}

              {/* Notizen */}
              {stein.notizen && (
                <div className="info-section">
                  <h3>Notizen</h3>
                  <p className="notizen-text">{stein.notizen}</p>
                </div>
              )}
            </div>
            {canEdit && (
              <div className="button-group">
                <button onClick={handleEdit} className="btn btn-submit">
                  <FontAwesomeIcon icon={faEdit} /> Bearbeiten
                </button>
                <button onClick={handleDelete} className="btn btn-reset">
                  <FontAwesomeIcon icon={faTrash} /> Löschen
                </button>
                <button onClick={handleBack} className="btn btn-back">
                  Zurück zur Liste
                </button>
              </div>
            )}
          </>
        )}
      </div>
    </main>
  );
}

export default SteinDetail;
